.content {
  max-width: 90%;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Nunito";
  font-size: 15px;
}
.header {
  font-size: 20px;
  text-align: start;
}
.long-text {
  max-height: 0;
  overflow: hidden;
  display: inline-block;
  transition: max-height 1s;
  font-family: "Nunito";
}
.expand {
  max-height: 50rem;
}
