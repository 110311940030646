/* Full-width background for the container */
.welcome-full-bg {
  background-color: #ffffff; /* Light yellow background */
  width: 100%;
  margin: 0; /* Ensure no gaps on the sides */
}

/* Card container */
.welcome-card {
  background-color: #fef3ef;
  border-radius: 12px;
  max-width: 95%;
  margin: 1.5rem auto; /* Center the card vertically and horizontally */
}

/* Title styling */
.welcome-title {
  font-weight: 700;
  font-size: 35px;
  font-family: "Nunito";
  padding: 1rem;
  line-height: 39.6px;
  background-color: #ffd58a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Text content styling */
.welcome-text {
  padding: 1rem;
  font-size: 20px;
  font-family: "Nunito";
  font-weight: 400;
  color: #000000;
  text-align: justify;
}
.welcome_btn {
  padding: 1em 1em;
}
/* Button styling */
.welcome-button {
  padding: 0.5rem 2rem;
  font-size: 16px;
  background-color: white;
  border: 1px solid #313131;
  border-radius: 16px;
  font-weight: 700;
  font-family: "Nunito";
  color: #313131;
  transition: background-color 0.3s ease;
}

.welcome-button:hover {
  background-color: #ffd58a;
  color: #313131;
}

/* Responsive styling using media queries */
@media (max-width: 1024px) {
  .welcome-title {
    font-size: 22px;
    padding: 0.8rem;
    line-height: 36px;
  }

  .welcome-text {
    font-size: 18px;
  }

  .welcome-button {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .welcome-title {
    font-size: 20px;
    padding: 0.7rem;
    line-height: 34px;
  }

  .welcome-text {
    font-size: 15px;
  }

  .welcome-button {
    font-size: 14px;
    padding: 0.5rem 1.2rem;
  }
}

@media (max-width: 425px) {
  .welcome-title {
    font-size: 20px;

    margin-top: 1rem;
    font-size: 18px;

    padding: 0.6rem;
    line-height: 30px;
  }

  .welcome-text {
    padding: 5px !important;
    font-size: 15px;
  }

  .welcome-button {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .welcome-title {
    margin-top: 1rem;
    font-size: 16px;
    padding: 0.5rem;
    line-height: 28px;
  }

  .welcome-text {
    padding: 5px !important;
    font-size: 13px;
  }

  .welcome-button {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 320px) {
  .welcome-title {
    font-size: 14px;
    padding: 0.4rem;
    line-height: 26px;
  }

  .welcome-text {
    padding: 5px !important;
    font-size: 13px;
  }

  .welcome-button {
    font-size: 10px;
    padding: 0.3rem 0.6rem;
  }
}
