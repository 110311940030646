.Faq_header {
  color: #003e90;
  font-family: "Nunito";
  font-weight: 600;
}
.Faq_desc {
  font-size: 15px;
  text-align: start;
  font-family: "Nunito";
  color: white;
}
.desc_body {
  background-color: #ff4e00;
}
.accordion {
  border: 1px solid black;
}
.wraapping {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
}
