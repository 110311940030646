/* Default styles */
.content-container h1 {
  font-size: 30px;
  font-family: "Quicksand";
  font-weight: 700;
  line-height: 60px;
  color: #003e90;
}
.content-container h3 {
  font-size: 25px;
  font-family: "Quicksand";
  font-weight: 700;
  line-height: 60px;
  color: #003e90;
}
.content-container p {
  font-size: 18px;
  font-family: "Nunito";
  font-weight: 400;
  text-align: justify;
}
.content-container h2 {
  font-size: 20px;
  font-family: "Quicksand";
  font-weight: 700;
  line-height: 60px;
  color: #000000;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .content-container h1 {
    font-size: 30px;
  }
  .content-container h3 {
    font-size: 25px;
  }
  .content-container p {
    font-size: 16px;
  }
  .content-container h2 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .content-container h1 {
    font-size: 30px;
  }
  .content-container h3 {
    font-size: 30px;
  }
  .content-container p {
    font-size: 15px;
  }
  .content-container h2 {
    font-size: 30px;
  }
}

@media (max-width: 525px) {
  .content-container h1 {
    font-size: 20px;
  }
  .content-container h3 {
    font-size: 25px;
  }
  .content-container p {
    font-size: 14px;
  }
  .content-container h2 {
    font-size: 25px;
  }
}

@media (max-width: 425px) {
  .content-container h1 {
    font-size: 20px;
  }
  .content-container h3 {
    font-size: 20px;
  }
  .content-container p {
    font-size: 14px;
  }
  .content-container h2 {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .content-container h1 {
    font-size: 18px;
  }
  .content-container h3 {
    font-size: 18px;
  }
  .content-container p {
    font-size: 13px;
  }
  .content-container h2 {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .content-container h1 {
    font-size: 16px;
  }
  .content-container h3 {
    font-size: 16px;
  }
  .content-container p {
    font-size: 12px;
  }
  .content-container h2 {
    font-size: 16px;
  }
}
