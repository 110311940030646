/* Base styles for the header */
.header {
  display: flex;
  flex-direction: column; /* Stack headerTop and headerBottom vertically */
}
.logo-lg {
  width: 200px;
  height: auto;
}

.logo-sm {
  width: 150px;
  height: auto;
}

.noMarginPadding {
  margin: 0;
  padding: 0;
}
/* Link spacing for desktop */
.desktop-nav-link {
  margin-right: 1rem; /* Adjust spacing between links */
}

/* Remove margin for the last link in the desktop view */
.desktop-nav-link:last-child {
  margin-right: 0;
  
}

/* Link spacing for mobile */
.mobile-nav-link {
 
  width: 100%;
 padding: .5rem;
  border-bottom: 1px solid rgb(86, 86, 86);
}

/* Remove margin for the last link in the mobile view */
.mobile-nav-link:last-child {
  margin-bottom: 2px;
  border: none
}

.headerTop {
  display: flex;
  justify-content: space-between;
  background-color: #003E90; /* Deep blue background color */
  color: #ffffff; /* White text and icon color */
  flex-direction: row;
}

.headerRetailer span {
  margin-right: 10px;
}

.headerTopLeft,
.headerTopRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; /* Ensure content is in a row */
}

.headerTopLeft {
  justify-content: flex-start;
}
.cartIconWrapper{
  margin-bottom: 4px !important;
}
.headerTopRight {
  justify-content: flex-end; /* Email address and button should be aligned to the end */
}

.headerTopLeft .headerPhone,
.headerTopLeft .headerTopSocialIcons,
.headerTopRight .headerRetailer,
.headerTopRight .headerLogin {
  color: #ffffff;
}

.headerTopLeft .headerPhone span,
.headerTopRight .headerRetailer span {
  font-size: var(--font-size-span); /* Use dynamic font size */
}

.headerTopLeft .headerTopSocialIcons svg,
.headerTopRight .headerLogin svg {
  width: 12px; /* Default icon size */
  height: 15px; /* Default icon size */
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.headerBottom__logo_main {
  width: var(--logo-width); /* Use dynamic width */
}

.headerBottom_navMenu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.headerBottom_navMenu ul li {
  margin: 0 10px;
}

.headerBottom_navMenu ul li a {
  color: #000000; /* Black color for menu items */
  text-decoration: none; /* Remove default underline */
  font-size: var(--font-size-nav-link); /* Use dynamic font size */
}

.headerBottom_navMenu ul li a:hover {
  border-bottom: 2px solid orange; /* Orange underline on hover */
}

.headerBotttom_icons {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  flex-direction: row; /* Ensure items are aligned in a row */
  margin: 0; /* Remove any extra margin around the container */
}

.headerBotttom_icons > * {
  margin-right: 10px; /* Space between icons */
}

.headerBotttom_icons > *:last-child {
  margin-right: 0; /* Remove margin from the last icon */
}

.headerBotttom_icons .profile-icon {
  margin-right: 10px; /* Remove margin if applied */
}

/* Media queries for different screen sizes */
@media (max-width: 992px){
  .headerTop {
    flex-direction: column; /* Stack items vertically on smaller screens */
    height: auto; /* Allow height to adjust based on content */
    padding: 5px 0; /* Adjust padding for extra-small screens */
  }
  .desktop-nav-link a{
font-size: 10px;
  }
}
@media (max-width: 767px) {
  .headerTop {
    flex-direction: column; /* Stack items vertically on smaller screens */
    height: auto; /* Allow height to adjust based on content */
    padding: 5px 0; /* Adjust padding for extra-small screens */
  }
  .cartIconWrapper{
    margin-bottom: 4px !important;
  }
  .headerTopLeft,
  .headerTopRight {
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    width: 100%; /* Ensure full width for alignment */
  }

  .headerTopLeft .headerPhone {
    margin-right: 0; /* Remove right margin to center the phone */
  }

  .headerTopRight {
    justify-content: center; /* Center items horizontally */
    margin-top: 5px; /* Adjust spacing between sections if needed */
  }

  .headerTopSocialIcons {
    display: flex;
    justify-content: center; /* Center social icons */
    margin: 0; /* Remove any margin if present */
  }

  .headerBottom__logo_main {
    width: 120px; /* Adjust logo size for mobile view */
  }

  .headerBottom_navMenu ul {
    display: none; /* Hide the nav links for mobile view */
  }

  .headerBottom_navMenu ul.show-mobile {
    display: flex; /* Show the nav links when needed for mobile */
    flex-direction: column; /* Stack nav links vertically on mobile */
    align-items: flex-start;
  }

  .headerLogin .account_box {
    padding: 2px 4px; /* Further reduce padding for mobile view */
    font-size: var(--font-size-account-box); /* Use dynamic font size */
  }

  .headerTopRight .headerRetailer {
    margin-right: 5px; /* Remove margin for the email address on mobile */
  }

  .headerBotttom_icons {
    flex-direction: row; /* Ensure icons are aligned in a row */
    align-items: center; /* Center items vertically */
  }

  .headerBotttom_icons > * {
    margin-right: 5px; /* Adjust margin for mobile view */
    width: 10px; /* Reduce icon size for mobile */
    height: 12px; /* Reduce icon size for mobile */
  }

  .headerBotttom_icons > *:last-child {
    margin-right: 0; /* Remove margin from the last icon */
  }

  /* Adjust search bar visibility for extra-small screens */
  .search_Bar {
    margin-bottom: 0; /* Remove bottom margin for consistency */
  }
}

@media (min-width: 768px) {
  .profile-icon{
    margin-right: 10px;
  }
  .headerLogin .account_box {
    padding: 5px 10px; /* Add padding for desktop view */
    font-size: var(--font-size-account-box); /* Use dynamic font size */
  }

  /* Increase font size for headerTop spans */
  .headerTopLeft .headerPhone span,
  .headerTopRight .headerRetailer span {
    font-size: var(--font-size-span); /* Use dynamic font size */
  }

  .header_mobile_menu {
    display: none; /* Hide ReorderIcon on larger screens */
  }
}

/* Logo size adjustments for various screen widths */
@media (max-width: 721px) {
  .headerBottom__logo_main {
    width: 200px;
  }
}

@media (max-width: 600px) {
  .headerBottom__logo_main {
    width: 180px;
  }
}

@media (max-width: 540px) {
  .headerBottom__logo_main {
    width: 170px;
  }
}

@media (max-width: 480px) {
  .headerBottom__logo_main {
    width: 160px;
  }
  .profile-icon{
    margin-right: 10px;
  }
}

@media (max-width: 440px) {
  .profile-icon{
    margin-right: 10px;
  }
  .headerBottom__logo_main {
    width: 150px;
  }
}

@media (max-width: 420px) {
  .profile-icon{
    margin-right: 10px;
  }
  .headerBottom__logo_main {
    width: 145px;
  }
}

@media (max-width: 412px) {
  .profile-icon{
    margin-right: 10px;
  }
  .headerBottom__logo_main {
    width: 140px;
  }
}

@media (max-width: 393px) {
  .profile-icon{
    margin-right: 10px;
  }
  .headerBottom__logo_main {
    width: 135px;
  }
}

@media (max-width: 360px) {
  .headerBottom__logo_main {
    width: 130px;
  }
}

@media (max-width: 320px) {
  .headerBottom__logo_main {
    width: 125px;
  }
}

/* Additional styles for specific elements */
.headerBottom_navMenu ul li a {
  font-size: var(--font-size-nav-link); /* Use dynamic font size */
}

.search_Bar {
  margin-bottom: 0; /* Remove bottom margin for consistency */
}

/* Font size variables for different screen sizes */
:root {
  --font-size-span: 1.8vw; /* Default font size for span, adjust as needed */
  --font-size-nav-link: 14px; /* Font size for navigation links */
  --font-size-account-box: 14px; /* Font size for account box */
  --logo-width: 200px /* Default logo width */
}
