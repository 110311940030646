/* ProductCard.css */

/* Base Styles */
.product-card {
    width: 291px !important;
    height: 328px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    border: 1px solid #D7D7D7 !important;
    transition: transform 0.2s ease-in-out !important;
    z-index: 2 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card:hover {
    transform: scale(1.03) !important;
}

.Product_Inner_Card {
    width: 255px !important;
    height: 129px !important;
    background-color: #ECF4FF !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 10px auto !important;
    border: none !important;
}

.card-img-top {
    width: 41px !important;
    height: 41px !important;
    object-fit: contain !important;
}

.body {
    padding: 0 15px !important;
    align-items: flex-start !important;
}

.discount-price {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 64px !important;
    height: 25px !important;
    border-radius: 100px !important;
    padding: 10px !important;
    gap: 10px !important;
    color: #0C7D3F !important;
    font-family: 'Nunito' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    background-color: #CEFFC9 !important;
    margin-bottom: 15px !important;
}

.card-title {
    font-family: 'Nunito' !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    color: #313131 !important;
    line-height: 24px !important;
    margin-bottom: 4px !important;
}

.text-reset {
    color: inherit !important;
    text-decoration: none !important;
}

.MuiButton-root.product_btnn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px 8px !important;
    border-radius: 12px !important;
    border: 1px solid #000000 !important;
    color: #313131 !important;
    font-weight: 700 !important;
    cursor: pointer !important;
    transition: background-color 0.3s !important;
    text-transform: capitalize !important;
    margin-top: 1rem !important;
    margin-bottom: 10px !important;
}

.MuiButton-root.product_btnn:hover {
    background-color: #f0f0f0 !important;
}

/* Small Devices (Phones, up to 425px) */
@media (max-width: 425px) {
    .product-card {
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
    }
    
    .Product_Inner_Card {
        width: 90% !important;
        height: auto !important;
        padding: 8px !important;
    }
    
    .card-img-top {
        width: 30px !important;
        height: 30px !important;
    }

    .body {
        padding: 5px !important;
    }

    .discount-price {
        font-size: 10px !important; /* Smaller font size */
        width: 50px !important;
        height: 20px !important;
        padding: 5px !important; /* Adjust padding */
    }

    .card-title {
        font-size: 14px !important; /* Smaller title */
    }

    .MuiButton-root.product_btnn {
        font-size: 10px !important; /* Smaller button text */
        padding: 4px 8px !important; /* Smaller padding */
        margin-bottom: 8px !important;
    }
}

/* Small Devices (Phones, less than 576px) */
@media (max-width: 575.98px) {
    .product-card {
        width: 100% !important;
        height: auto !important;
        margin: 0 auto !important;
    }
    
    .Product_Inner_Card {
        width: 90% !important;
        height: auto !important;
        padding: 10px !important;
    }
    
    .card-img-top {
        width: 35px !important;
        height: 35px !important;
    }

    .body {
        padding: 10px !important;
    }

    .discount-price {
        font-size: 12px !important; /* Smaller font size */
        width: 55px !important;
        height: 22px !important;
        padding: 6px !important; /* Adjust padding */
    }

    .card-title {
        font-size: 16px !important; /* Smaller title */
    }

    .MuiButton-root.product_btnn {
        font-size: 12px !important; /* Smaller button text */
        padding: 5px 5px !important; /* Adjust padding */
        margin-bottom: 8px !important;
    }
}

/* Medium Devices (Tablets, up to 1024px) */
@media (max-width: 1024px) {
    .product-card {
        width: 300px !important;
        height: auto !important;
        margin: 0 auto !important;
    }
    
    .Product_Inner_Card {
        width: 280px !important;
        height: 140px !important;
    }
    
    .card-img-top {
        width: 60px !important;
        height: 60px !important;
    }

    .discount-price {
        font-size: 14px !important; /* Smaller font size */
    }

    .card-title {
        font-size: 16px !important; /* Smaller title */
    }

    .MuiButton-root.product_btnn {
        font-size: 13px !important; /* Smaller button text */
        padding: 6px 12px !important; /* Adjust padding */
        margin-bottom: 8px !important;
    }
}

/* Medium Devices (Tablets, up to 1024px) */
@media (min-width: 576px) and (max-width: 991.98px) {
    .product-card {
        width: 220px !important;
        height: auto !important;
    }
    
    .Product_Inner_Card {
        width: 200px !important;
        height: 100px !important;
    }
    
    .card-img-top {
        width: 38px !important;
        height: 38px !important;
    }

    .discount-price {
        font-size: 15px !important;
    }

    .card-title {
        font-size: 17px !important;
    }

    .MuiButton-root.product_btnn {
        font-size: 15px !important;
        padding: 3px 12px !important;
        margin-bottom: 10px !important;
    }
}

/* Large Devices (Desktops, 992px and up) */
@media (min-width: 992px) {
    .product-card {
        width: 291px !important;
        height: 328px !important;
    }
    
    .Product_Inner_Card {
        width: 255px !important;
        height: 129px !important;
    }
    
    .card-img-top {
        width: 41px !important;
        height: 41px !important;
    }

    .discount-price {
        font-size: 16px !important;
    }

    .card-title {
        font-size: 22px !important;
    }

    .MuiButton-root.product_btnn {
        font-size: 16px !important;
        padding: 8px 12px !important;
        margin-bottom: 10px !important;
    }
}

/* Extra Large Devices (Large Desktops, 1200px and up) */
@media (min-width: 1200px) {
    .product-card {
        width: 310px !important;
        height: 350px !important;
    }
    
    .Product_Inner_Card {
        width: 270px !important;
        height: 140px !important;
    }
    
    .card-img-top {
        width: 45px !important;
        height: 45px !important;
    }

    .discount-price {
        font-size: 17px !important;
    }

    .card-title {
        font-size: 24px !important;
    }

    .MuiButton-root.product_btnn {
        font-size: 14px !important;
        padding: 5px 5px !important;
        margin-bottom: 10px !important;
    }
}
