.circle_text h1 {
  text-align: start;
  font-size: 36px;
  margin-top: 10px;
  font-family: "Nunito";
}

/* Media query for extra small screens (xs) */
@media (max-width: 767px) {
  .circle_text h1 {
    font-size: 25px;
    font-weight: 500;
  }
}
