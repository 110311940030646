/* Base Styles */
.circle_text h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand";
  text-align: center;
  font-size: 45px;
  line-height: 60px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #003e90;
  font-weight: 700;
}
.school_para41 {
  font-size: 18px;
  font-family: "Quicksand";
  font-weight: 400;
  text-align: center;
}
.school_para,
.school_para2,
.school_para4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito";
  text-align: justify;
  display: inline-block;
}

.school_para2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 39.6px;
  margin: 0;
}
.school_para22 {
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: 700;
  line-height: 39.6px;
  margin: 0;
}
.content1 {
  max-width: 100%;
  font-family: "Nunito";
  font-size: 15px;
  text-align: justify;
}

.additional {
  margin-top: 2rem;
}

.additional p {
  font-family: "Nunito";
  text-align: justify;
  display: inline-block;
}

.support-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.support-item p {
  font-family: "Nunito";
  text-align: justify;
  display: inline-block;
  font-size: var(--font-span);
}

.long-text {
  max-height: 0;
  overflow: hidden;
  display: inline-block;
  transition: max-height 1s;
  font-family: "Nunito";
}

.expand1 {
  max-height: 50rem;
}

.schoolbg {
  background-image: url("../../Image/ourteam.png");
  position: relative;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.additional {
  background-image: url("../../Image/welcomepage5.png");
  position: relative;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-containerschool {
  margin-top: 110px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.image-containerschool img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.custom-icon {
  font-size: 32px !important;
  color: #ffd700;
}

/* Responsive Media Queries */

/* 2560px (Large Screen Monitors) */
@media (max-width: 2560px) {
  .circle_text h1 {
    font-size: 45px;
  }

  .support-item p {
    font-size: 18px;
  }
}

/* 1440px (Desktops) */
@media (max-width: 1440px) {
  .circle_text h1 {
    font-size: 38px;
  }

  .support-item p {
    font-size: 17px;
  }

  .image-containerschool {
    height: 400px;
  }
}

/* 1024px (Tablets Landscape) */
@media (max-width: 1024px) {
  .circle_text h1 {
    font-size: 32px;
  }

  .support-item p {
    font-size: 16px;
  }

  .image-containerschool {
    height: 350px;
  }
}

/* 768px (Tablets Portrait) */
@media (max-width: 768px) {
  .school_para {
    font-size: 15px;
  }
  .school_para41 {
    font-size: 15px;
    font-family: "Quicksand";
    font-weight: 400;
  }
  .circle_text h1 {
    font-size: 20px;
  }

  .support-item {
    flex-direction: row;
  }
  .custom-icon {
    font-size: 20px !important;
    color: #ffd700;
  }
  .school_para22 {
    font-weight: 700;
    font-family: "Nunito";
    letter-spacing: 1px;
    font-size: 18px;
  }
  .support-item p {
    font-size: 15px;
    margin: 0;
  }
  .school_para2 {
    font-size: 18px;
  }
  .image-containerschool {
    height: 300px;
  }
}

/* 425px (Large Mobile Phones) */
@media (max-width: 425px) {
  .school_para2 {
    font-size: 18px;
  }
  .school_para41 {
    font-size: 14px;
    font-family: "Quicksand";
    font-weight: 400;
  }
  .circle_text h1 {
    font-size: 20px;
  }

  .support-item p {
    font-size: 14px;
    text-align: justify;
  }

  .image-containerschool {
    height: 250px;
  }
}

/* 375px (Standard Mobile Phones) */
@media (max-width: 375px) {
  .school_para2 {
    font-size: 18px;
  }
  .school_para41 {
    font-size: 14px;
    font-family: "Quicksand";
    font-weight: 400;
  }
  .circle_text h1 {
    font-size: 18px;
  }

  .support-item p {
    font-size: 13px;
    text-align: justify;
  }

  .image-containerschool {
    height: 200px;
  }
}

/* 320px (Small Mobile Phones) */
@media (max-width: 320px) {
  .school_para41 {
    font-size: 14px;
    font-family: "Quicksand";
    font-weight: 400;
  }
  .circle_text h1 {
    font-size: 16px;
  }

  .support-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .support-item p {
    font-size: 12px;
    text-align: justify; /* Ensure justification on small devices */
  }

  .image-containerschool {
    height: 180px;
  }
}
