.productPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
}

.star-iconproduct{
  width: 23px;
  height: 23px;
  top: 10px;
  left: 0px;
  opacity: 0.7;
  z-index: 1;
  animation: float 2s ease-in-out infinite;
}
.product_back_clipart{
  position: relative;
}
.about_paragraph5 {
  
  z-index: 2;
  font-size: 18px;

  font-weight: 400;
 
  font-family: "Quicksand";
  color: #1f1f1f;
}
.prodcutPageTop {
  display: flex;
  width: auto;
  gap: 0.2rem;
  justify-content: space-around;
}

.productBack h1 {
  font-size: 45px;
  line-height: 60px;
  font-family: "Quicksand";
  color: #003e90;
  font-weight: 700;
  margin-top: 2rem;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  width: 80%;
}

.products1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;

  margin: 1rem 0;
}
.filterBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 20%;
  margin: 1rem 0 0 0.8rem;
  background-color: #ffffff4d;
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  box-shadow: 0 0 5px rgba(255, 137, 53, 0.329);
}

.priceFilter {
  display: flex;
  flex-direction: column;

  align-items: start;
}

.priceSlider {
  width: 90%;
}
.priceSlider .css-187mznn-MuiSlider-root {
  height: 3px !important;
  color: #414141 !important;
}

.priceSelector {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 90%;
  margin: 10px 0;
}

.priceOption {
  width: 90%;
  height: 2rem;
}

.toText {
  font-size: 16px;
  font-weight: 400;
  color: #414141;
}
.filter_divider {
  width: 100%;
  height: 0.8px;
  margin: 1.5rem 0;
  background-color: #d7d5d2d4;
}
.menu_item {
  color: white !important;
  box-shadow: rgb(255, 255, 255) !important;
}

.categoryBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.category-link {
  display: flex;
  align-items: center;
  list-style: none;
  color: rgba(0, 0, 0, 0.61);
  font: 400 0.8vmax "Roboto";
  margin: 0.4vmax;
  cursor: pointer;
}
.category-label {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-checkbox {
  width: 16px;
  height: 16px;
}

.category-checkbox:checked + .category-label::before {
  background-color: #414141;
}

.ratingsFilter {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.ratingsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.paginationBox {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 2rem 0 2rem 0;
}
.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1rem;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px 5px 5px 5px;
}

.page-item:first-child {
  border-radius: 5px 5px 5px 5px;
}

.page-item:last-child {
  border-radius: 5px 5px 5px 5px;
}
.page-link {
  text-decoration: none;
  font: 300 0.9vmax "Nunito";
  color: rgb(2, 2, 2);
  transition: all 0.3s;
  background-color: transparent !important;
  border: none;
}

.page-item:hover {
  background-color: #ff4e00;
}

.page-item:hover .page-link {
  color: rgb(255, 255, 255);
}

.pageItemActive {
  background-color: #ff4e00;
}

.pageLinkActive {
  color: white;
}

.css-187mznn-MuiSlider-root {
  color: #170f05 !important;

  height: 1.2px !important;
}
.css-eg0mwd-MuiSlider-thumb {
  width: 10px !important;
  height: 10px !important;
}

.css-ahj2mt-MuiTypography-root {
  text-align: center;
}

.categoryBox {
  padding: 0;
}

.category-link:hover {
  color: tomato;
}

.css-1t2bqnt {
  color: #170f05 !important;
}

.css-exkjcx {
  color: #414141 !important;
}

.filterBox > fieldset {
  border: 1px solid rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 1100px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .filterBox {
    width: 30vmax;
  }
  .page-link {
    font: 300 1.2vmax "Roboto";
  }
  .products1 {
    width: 55vw;
  }
  .category-link {
    font: 400 1.3vmax "Roboto";
  }
}

@media screen and (max-width: 800px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .productBack h1 {
    font-size: 45px;
    line-height: 60px;
    font-family: "Quicksand";
    color: #003e90;
    font-weight: 700;
    margin-top: 2rem;
  }
  .filterBox {
    width: 40vmax;
  }
  .products1 {
    width: 45vw;
  }

  .page-link {
    font: 300 1.5vmax "Roboto";
  }
  .category-link {
    font: 400 1.6vmax "Roboto";
  }
}

@media screen and (max-width: 600px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .productBack h1 {
    font-size: 35px;
    line-height: 60px;
    font-family: "Quicksand";
    color: #003e90;
    font-weight: 700;
    margin-top: 2rem;
  }
  .prodcutPageTop {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10px;
  }
  .filterBox {
    width: 60%;
    margin: 1rem 0 0 0;
  }

  .products {
    width: 100%;
    margin: 1rem 0 0 0;
  }
  .products1 {
    width: 100%;
    margin: 1rem 0 0 0;
  }
}
@media (max-width: 575.98px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  /* xs breakpoint */
  .responsive-heading {
    font-size: 24px; /* Adjust this size as needed */
  }
}

/* Center the categoriesFilter container */
.categoriesFilter {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Center horizontally */
  text-align: start; /* Center text */
  /* Add padding for spacing */
}

/* Style for the list of categories */
.categoryBox {
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
  display: flex; /* Use flexbox to arrange items */
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  justify-content: center; /* Center items horizontally */
}

/* Style individual category items */
.category-item {
  margin: 5px; /* Add margin between items */
}

/* Style the label and checkbox */
.category-label {
  display: flex;
  align-items: center; /* Align checkbox and text vertically */
}

.category-label input {
  margin-right: 5px; /* Add space between checkbox and label text */
}

/* Responsive adjustments for very small screens */
@media (max-width: 576px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .categoriesFilter {
    padding: 15px; /* Add padding for better spacing on small screens */
  }

  .categoryBox {
    flex-direction: column; /* Stack items vertically on very small screens */
    align-items: start; /* Center items horizontally */
  }

  .category-item {
    margin: 10px 0; /* Adjust margins for stacked items */
  }
}

.paginationContainer {
  padding: 0 5px; /* Add horizontal padding to prevent overflow */
}

.pagination {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap if they don't fit */
  justify-content: center; /* Center the pagination */
}

.page-item {
  margin: 0 5px; /* Add a small margin between items */
}

.page-link {
  padding: 0; /* Adjust padding for better spacing */
}

@media (max-width: 576px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .productBack h1 {
    font-size: 20px;
    line-height: 40px;
    font-family: "Quicksand";
    color: #003e90;
    font-weight: 700;
    margin-top: 1rem;
  }
  /* xs breakpoint */
  .paginationContainer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pagination {
    font-size: 0.75rem; /* Reduce font size for small screens */
  }

  .page-item {
    margin: 0 2px; /* Reduce margin for small screens */
  }
}
.pageItemActive {
  background-color: #ff4e00; /* Set background color to black */
  border-color: #ff4e00; /* Ensure the border color matches */
}

.pageLinkActive {
  color: #ffffff; /* Set the text color to white for contrast */
}

.circle_text h1 {
  text-align: start !important;
  font-size: 36px;
  margin-top: 10px;
  font-family: "Nunito";
}

/* Media query for extra small screens (xs) */
@media (max-width: 767px) {
  .about_paragraph5 {
    font-size: 14px;
  }
  .circle_text h1 {
    font-size: 25px;
    font-weight: 500;
  }
}
