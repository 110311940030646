/* Cart Icon Styles */
.cartIconWrapper {
  position: relative;
  margin-bottom: 10px;
}

.cartIcon {
  font-size: 1.8rem;
  cursor: pointer;
  color: #000000 !important; 
  position: relative;
}
.cartIcon > svg {
  font-size: 1.7rem
}

.cartIcon:hover {
  color: #ed1c24; /* Hover icon color (red) */
}
.cartItemCount {
  position: absolute;
  top: -2px;
  right: -8px;
  background-color: #ed1c24;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  padding: 0 5px;
}
