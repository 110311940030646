.training_head h1 {
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
  color: #003e90;
  margin-bottom: 1rem;
  z-index: 2;
  position: relative;
}
.training_header p{
  font-Weight: 400;
  font-Family: "Quicksand";
  text-Align: center;
  font-Size: 18px;
}
.training_head1 h1 {
  font-family: "Quicksand", sans-serif;
  text-align: start;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: #003e90;

  z-index: 2;
  position: relative;
}
/* Media query for extra small screens (xs) */
@media (max-width: 320px) {
  .training_head h1,
  .training_head1 h1 {
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0.8rem;
  }
  .training_header p{
    font-Weight: 400;
    font-Family: "Quicksand";
    text-Align: center;
    font-Size: 15px;
  }
}

/* Media query for small screens (375px) */
@media (max-width: 375px) {
  .training_head h1,
  .training_head1 h1 {
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0.8rem;
  }
  .training_header p{
    font-Weight: 400;
    font-Family: "Quicksand";
    text-Align: center;
    font-Size: 15px;
  }
}

/* Media query for small to medium screens (425px) */
@media (max-width: 425px) {
  .training_head h1,
  .training_head1 h1 {
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .training_header p{
    font-Weight: 400;
    font-Family: "Quicksand";
    text-Align: center;
    font-Size: 15px;
  }
}

/* Media query for tablets (768px) */
@media (max-width: 768px) {
  .training_head h1 {
    font-size: 20px;
    line-height: 50px;
    text-align: center;
  }
  .training_head1 h1 {
    font-size: 20px;
    line-height: 48px;
    text-align: start;
  }
  .training_header p{
    font-Weight: 400;
    font-Family: "Quicksand";
    text-Align: center;
    font-Size: 15px;
  }
}
