body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Nunito";
}
.carClipArtAbout {
  position: absolute;
  top: 20px;
  left: 0;
  width: 51px;
  height: auto;
  opacity: 0.8;
  animation: float 2s ease-in-out infinite;
}
.about_head3 {
  font-family: "Quicksand";
  text-align: start;
  font-size: 45px;
  margin-top: 2rem;
  line-height: 60px;
  position: relative; /* Key for positioning the ::after pseudo-element */
  color: #003e90;
  font-weight: 700;
  z-index: 1;
  display: inline-block; /* Ensure the underline is relative to the text width */
}

.about_head3::after {
  content: ""; /* Creates the underline */
  position: absolute;
  bottom: 5px; /* Positions it slightly below the text */
  left: 50%; /* Start the underline at the middle of the H1 */
  transform: translateX(-50%); /* Adjust for perfect centering */
  width: 0; /* Start with width 0 for animation */
  height: 5px; /* Set a fixed height for the underline */
  background-image: url("../Image/home/underline.png"); /* Decorative zigzag underline image */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure it scales well */
  z-index: -1;
  animation: zigzagUnderlineAbout 6s ease-out infinite; /* Apply animation */
}

@keyframes zigzagUnderlineAbout {
  0% {
    width: 0; /* Start with width 0 */
    height: 5px;
  }
  50% {
    width: 100%; /* Make the underline span the full width of the h1 */
    height: 5px;
  }
  100% {
    width: 0; /* End with width 0 */
    height: 5px;
  }
}

.globeClipArtAbout {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 81px;
  height: auto;
  opacity: 0.5;
  animation: float 2s ease-in-out infinite;
}
.faq {
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.Testimonial_container h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  text-align: center;
  font-family: "Quicksand";
  color: #1f1f1f;
}
.founder_container {
  position: relative;
  padding: 0;
  background-color: #fff7f4 !important;
}
.icon {
  position: absolute;
}
.star-icon {
  width: 43px;
  height: 43px;
  top: 51px;
  left: 0px;
  opacity: 0.7;
  z-index: 1;
  animation: float 2s ease-in-out infinite; /* Apply animation */
}

/* Book Icon */
.book-icon {
  width: 50.77px;
  height: 50.77px;
  top: 320.25px;
  left: 714.92px;
  transform: rotate(32.46deg);
  animation: float 2s ease-in-out infinite; /* Apply animation */
}

/* Planet Icon */
.planet-icon {
  width: 84px;
  height: 68px;
  top: 146px;
  left: 1456px;
  animation: float 2s ease-in-out infinite; /* Apply animation */
}
@keyframes float {
  0% {
    transform: translateY(0); /* Starting position */
  }
  50% {
    transform: translateY(-10px); /* Move up */
  }
  100% {
    transform: translateY(0); /* Return to original position */
  }
}
.playapp_container{
  position: relative;
  background-color: #edf5da !important;
  padding: 1rem 0;
  margin: 2rem 0;
}
.traitImage {
  width: 50%; /* Makes the image take full width of its container */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 8px; /* Adds smooth rounded corners */
  object-fit: cover; /* Ensures the image covers the entire container without distortion */
/* Spacing between the image and text */
}
.about_paragraph1 {
  font-size: 16px;
  color: #313131;
  text-align: justify;
  font-family: "Nunito";
  width: 80%;
}
.about_paragraph {
  font-size: 16px;
  color: #313131;
  text-align: justify;
  font-family: "Nunito";
}
.about_title_new {
  color: #003e90;
  font-family: "Quicksand";
  font-size: 35px;
  font-weight: 700;
}
.about_paragraph2 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 18px;

  font-weight: 400;
  text-align: center;
  font-family: "Quicksand";
  color: #1f1f1f;
}
.about_head {
  font-family: "Quicksand";
  text-align: center; /* Center the text */
  font-size: 45px;
  margin-top: 2rem;
  line-height: 60px;
  position: relative; /* Key for positioning the ::after pseudo-element */
  color: #003e90;
  font-weight: 700;
  z-index: 1;
  display: block; /* Makes the <h1> a block element */
  width: max-content; /* Ensures the width of the <h1> is just as wide as its content */
  margin-left: auto;
  margin-right: auto; /* Centers the block element */
}

.about_head::after {
  content: ""; /* Creates the underline */
  position: absolute;
  bottom: 5px; /* Positions it slightly below the text */
  left: 50%; /* Start the underline at the middle of the H1 */
  transform: translateX(-50%); /* Adjust for perfect centering */
  width: 0; /* Start with width 0 for animation */
  height: 5px; /* Set a fixed height for the underline */
  background-image: url("../Image/home/underline.png"); /* Decorative zigzag underline image */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure it scales well */
  z-index: -1;
  animation: zigzagUnderlineAbout 6s ease-out infinite; /* Apply animation */
}

@keyframes zigzagUnderlineAbout {
  0% {
    width: 0; /* Start with width 0 */
    height: 5px;
  }
  50% {
    width: 100%; /* Make the underline span the full width of the text */
    height: 5px;
  }
  100% {
    width: 0; /* End with width 0 */
    height: 5px;
  }
}

.about_head3 {
  font-family: "Quicksand";
  text-align: start;
  font-size: 45px;
  margin-top: 2rem;
  line-height: 60px;
  position: relative;
  color: #003e90;
  font-weight: 700;
}
.about_head2 {
  font-family: "Quicksand";
  text-align: start;
  margin-top: 2rem;
  font-size: 30px;
  line-height: 50px;

  color: "#003e90";
  font-weight: 700;
}
.intro_text {
  font-family: "Nunito";
  max-width: 800px;
  line-height: 1.5;
  color: #292929;
  font-size: 19px;
  font-weight: 400;
  text-align: justify;
}
.founderName {
  font-family: "Nunito";
  font-weight: 700;
  font-size: 16px;
  color: orange;
}
.trait-card {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for subtle effect */
  border-color: transparent !important;
  height: 150px;
  padding: 16px; /* Adjust padding as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.trait-image {
  height: 80px;
  margin: 0;
}

@media (max-width: 600px) {
 
  .Testimonial_container span {
    font-size: 14px;
  }
  .about_head {
    font-size: 20px; /* Adjust font size for mobile */
    
     /* Horizontally centers the element */
}
  .about_head::after {
    width: 0; /* Start width at 0 for animation */
  }
  .about_title_new {
    color: #003e90;
    font-family: "Quicksand";
    font-size: 25px;
    font-weight: 700;
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .trait-image {
    height: 70px !important; /* Responsive styling for smaller screens */
  }
}

.trait-letter {
  color: #ff4e00;
  font-size: 1.5rem; /* Adjusted for scalable sizing */
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.trait-text {
  color: #61677a;
  font-size: 0.875rem; /* Use rem for responsive sizing */
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}
/* Global font adjustments for smaller screens */
@media (max-width: 1024px) {

  .about_head {
    font-size: 20px; /* Adjust font size for mobile */
   
  ; /* Horizontally centers the element */
}

  .about_head::after {
    width: 0; /* Start width at 0 for animation */
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .about_head,
  .about_head2,
  .about_head3 {
    font-size: 48px;
    line-height: 50px;
    margin: 0;
  }
  .about_paragraph,
  .about_paragraph2 {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
 
  .traitImage {
    border-radius: 6px; /* Slightly smaller rounded corners */
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .about_head {
    margin: 0;
    font-size: 20px;
    line-height: 45px;
  }
  .about_head2 {
    margin: 0;
    font-size: 20px;
    line-height: 40px;
  }
  .about_head3 {
    margin: 0;
    font-size: 20px;
    line-height: 40px;
  }
  .about_paragraph,
  .about_paragraph2 {
    font-size: 15px;
  }
}

@media (max-width: 425px) {



  
  .Testimonial_container span {
    font-size: 14px;
  }

  .about_title_new {
    color: #003e90;
    font-family: "Quicksand";
    font-size: 20px;
    font-weight: 700;
  }
  .traitImage {
    width: 60%;
    margin: 0 auto; /* Centers the image */
    border-radius: 4px; /* Slightly smaller rounded corners */
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .container .about_head {
    font-size: 20px !important;
   
  }
  .container .about_head2 {
    margin: 0;
    font-size: 20px !important;
    line-height: 35px !important;
  }
  .container .about_head3 {
    margin: 0;
    font-size: 20px !important;
    line-height: 35px !important;
  }
  .about_paragraph,
  .about_paragraph2 {
    font-size: 15px;
  }
  .trait-image {
    height: 60px;
  }
}

@media (max-width: 375px) {
 

 
  .Testimonial_container span {
    font-size: 14px;
  }

  .about_title_new {
    color: #003e90;
    font-family: "Quicksand";
    font-size: 18px;
    font-weight: 700;
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .container .about_head {
    margin: 0;
    text-align: center; /* Ensure text is centered */
    width: max-content;
    font-size: 20px;
    line-height: 35px;
  }
  .container .about_head2 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
  }
  .container .about_head3 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
  }
  .about_paragraph,
  .about_paragraph2 {
    font-size: 12px;
  }
  .trait-image {
    height: 50px;
  }
}

@media (max-width: 320px) {
  
  .Testimonial_container span {
    font-size: 14px;
  }

  .about_title_new {
    color: #003e90;
    font-family: "Quicksand";
    font-size: 18px;
    font-weight: 700;
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .about_head {
    margin: 0;
    font-size: 24px;
    text-align: center; /* Ensure text is centered */
    width: max-content;
    line-height: 30px;
  }
  .about_head2 {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .about_head3 {
    margin: 0;
    font-size: 20px;
    line-height: 25px;
  }
  .about_paragraph,
  .about_paragraph2 {
    font-size: 11px;
  }
  .trait-image {
    height: 40px;
  }
}

/* Specific adjustments for images */
.trait-card {
  padding: 8px; /* Reduce padding for smaller screens */
}

.trait-letter {
  font-size: 1.25rem;
}

.trait-text {
  font-size: 0.75rem;
}

/* Adjustments for 320px screens */
@media (max-width: 320px) {
  .underline_header_about {
    display: block; /* Ensure it behaves like a block element */
    margin: 0 auto; /* Center the image horizontally */
    width: 0; /* Start with a width of 0 for animation */
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    /* Center the image */
    transform: translateX(-50%); /* Adjust for centering */
    z-index: -1; /* Keep the image behind other content */
    animation: zigzagUnderlineAbout 6s ease-out infinite; /* Infinite animation */
  }
  .faq {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .trait-card {
    height: 140px;
  }
  .trait-letter {
    font-size: 1rem;
  }
  .trait-text {
    font-size: 0.65rem;
  }
}
