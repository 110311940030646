/* Ensure the Outfit font is applied to the entire container */
body {
  font-family: "Nunito";
}

/* Specific styles for social media icons */
.social-media {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adjust the gap between icons as needed */
}

.social-icon {
  font-size: 30px; /* Adjust the icon size as needed */
  color: #000; /* Adjust the icon color as needed */
  cursor: pointer; /* Add a pointer cursor on hover */
  font-family: "Nunito"; /* Apply Outfit font */
}

.social-media {
  display: flex;
  gap: 10px; /* Adjust the gap between icons if needed */
}

.social-icon {
  font-size: 24px; /* Adjust the icon size if needed */
}

/* Add responsive styles */
@media (max-width: 768px) {
  .social-media {
    margin-bottom: 1rem; /* Add margin-bottom for mobile view */
  }
}
