/* General Footer Styling */
.footer-wrapper {
  margin-top: 20px; /* You can adjust this value to control the distance */
  padding-top: 20px; /* Optional, add padding at the top of the footer */

}
.footer-custom {
  font-family: "Nunito" !important;
  color: white !important;
}
.mail-icon {
  font-size: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

.textt {
  display: flex;
  align-items: center;
  font-size: var(--font-span);
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-start {
  text-align: left;
}
/* Ensure the text is properly positioned */

.footer-custom a {
  color: white !important;
  text-decoration: none !important;
}

.footer-custom h6 {
  color: #ff4e00 !important;
}

/* Your base link style */
.footer_links {
  color: white;
  font-size: var(--font-span);
  font-family: "Nunito", sans-serif;
  text-decoration: none;
  font-weight: 600;
}

.footer_links:hover {
  text-decoration: underline;
}

/* Add higher specificity for the active class */
footer .footer_links.active_link {
  color: #ff4e00 !important; /* Highlight color for active links */
  font-weight: 700;
}

/* Custom Button Styling */
.custom-btn {
  background-color: #ff4e00 !important;
  color: white !important;
  text-transform: none;
  font-family: "Nunito" !important;
}
.button-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Box Styling */
.box {
  background-color: #ff4e00;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-text {
  font-size: var(--font-span);
}

.box-text-two {
  font-size: var(--font-span);
  margin: 0 !important;
}

/* Icon and Text Column Styling */
.icon-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 2vw;
  color: white;
}

.text-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.text {
  font-size: var(--font-P);
  font-weight: 500;
  color: white;
}

.text-two {
  font-size: 12px;
  color: rgb(222, 222, 222);
}
.footer_links {
  font-size: 15px;
}
/* Responsive Adjustments */
@media (max-width: 767px) {
  .footer_links {
    font-size: 15px;
  }
  .icon {
    font-size: 1.5rem;
  }
  .navbar-brand {
    margin-top: 1rem;
  }

  .text-two {
    font-size: 14px;
  }
}

/* Footer Wrapper Styling */
.footer-wrapper {
  position: relative;
  margin-top: 6rem;
}

/* Overlay Container Styling */
.overlay-container {
  background-image: url("../../../Image/ctanew.png"); /* Update the path to your image */
  background-size: cover;
  background-position: center;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: white;
  font-family: "Nunito";
}
.textt {
  font-size: var(--font-span);
  font-family: "Nunito";
  color: white;
}
.text-reset {
  font-size: 15px;
  font-family: "Nunito";
}
.overlay-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fdfdfd;
  margin-bottom: 1rem;
}

.overlay-content h1 {
  font-size: var(--font-h2);
  margin: 0;
  font-family: "Nunito";
}

.overlay-content p {
  font-size: var(--font-span);
  margin: 0;
  font-family: "Nunito";
}

.overlay-content .btn {
  background-color: #003e90;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.overlay-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .footer-wrapper {
    margin-top: 130px; /* You can adjust this value to control the distance */
    padding-top: 20px; /* Optional, add padding at the top of the footer */
  
  }
  .footer_links {
    font-size: 15px;
  }
  .text-two {
    font-size: 14px;
  }
  .textt {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .overlay-container {
    width: 94%;
  }
  .overlay-content p {
    font-size: 14px;
    margin: 0;
    font-family: "Nunito";
  }
  .box-col {
    flex: 1 0 50%;
    box-sizing: border-box;
  }
  .button-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay-heading {
    font-size: 14px;
  }
  .overlay-content .btn {
    font-size: 14px;
  }
  .button-col button {
    background-color: #c01c1c;
    color: white;
    border: none;
    padding: 10px 10px;
    text-transform: none;
    font-size: 8px;
    margin-top: 5px;
  }
}
@media (min-width: 768px) {
  .footer_links {
    font-size: 15px;
  }
  .overlay-content .btn {
    font-size: 14px;
  }
  .text-two {
    font-size: 14px;
  }
  .textt {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .btn-normal-case {
    text-transform: none;
  }
}
/* Wrapper for custom styling */
.custom-input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.custom-input {
  width: 100%;
}

.form-label {
  color: black;
}

/* Adjust label when input is focused */
.custom-input .form-control:focus ~ .form-label,
.custom-input .form-control:not(:placeholder-shown) ~ .form-label {
  top: -0.75rem; /* Adjust position */
  left: 0.75rem; /* Adjust position */
  color: #007bff; /* Change to match your theme */
  padding: 0 0.25rem; /* Add padding to prevent overlap */
  font-size: 0.65rem; /* Adjust font size */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 767px) {
  .textt {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .custom-input .form-label {
    font-size: 0.65rem; /* Adjust font size for mobile */
    top: -0.5rem; /* Adjust position for mobile */
  }

  .custom-input .form-control {
    padding-top: 1.5rem; /* Ensure enough space for the label */
  }
}
.custom-input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.custom-input-field {
  width: 100%;
  padding: 12px 12px 12px 16px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.custom-floating-label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #7a7a7a;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.custom-input-field:focus ~ .custom-floating-label,
.custom-input-field:not(:placeholder-shown) ~ .custom-floating-label {
  top: -10px; /* Move label up */
  font-size: 0.8rem;
  color: #ffffff; /* Change color on focus */
}

.custom-input-field:focus {
  outline: none;
  border-color: #ff4e00;
}
