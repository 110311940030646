/* General Styles */
.scrolling-header {
  background-color: #FF4E00;
  color: #fff;
  padding: 1px 0;
  text-align: center;
  width: 100%; /* Full width */
  top: 0;
  z-index: 1000;
  overflow: hidden; /* Hide overflow to enable the scrolling effect */
  position: relative;
}

.scrolling-text-wrapper {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  animation: marquee 15s linear infinite; /* Marquee effect */
}

.scrolling-text {
  font-family: 'Nunito';
  display: inline-block;
  font-size: var(--font-span, 1rem); /* Use CSS variable or default size */
  margin: 0;
  padding: 0.5rem;
  white-space: nowrap; /* Prevent line breaks */
}

/* Keyframes for marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start off-screen from the right */
  }
  100% {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}

/* Responsive Media Queries */
@media (max-width: 767px) {
  .scrolling-header {
    padding: 0.1rem 0;
  }

  .scrolling-text {
    font-size: var(--font-span, 0.8rem);
    animation-duration: 20s; /* Slower scroll for smaller screens */
  }
}
