.service_description_tag{
    font-size: 20px;
    font-family: 'Quicksand';
    font-weight: 600;
}
@media (max-width: 600px){
    .service_description_tag{
        font-size: 18px;
       
    }
}
@media (max-width: 525px){
    .service_description_tag{
        text-align: center;
        font-size: 17px;
       
    }
}
@media (max-width: 425px){
    .service_description_tag{
        font-size: 16px;
        text-align: center;

    }
}
@media (max-width: 375px){
    .service_description_tag{
        font-size: 16px;
        text-align: center;

    }
}
@media (max-width: 320px){
    .service_description_tag{
        font-size: 16px;
        text-align: center;

    }
}