/* Basic Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito';
  overflow-x: hidden;
}
a {
  text-decoration: none !important;
}

.popup {
  position: fixed;
  top: 80%;
  right: 1%;
  transform: translateY(-50%);
  width: 300px;
  height: 200px;
  background: #ff9ea6;
  color: #721c24;
  padding: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
 
}

.close-btn:hover {
  color: #a71d2a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .popup {
    width: 250px;
    height: 180px;
    top: 75%;
    padding: 15px;
    font-size: 12px;
  }

  .popup p {
    font-size: 12px;
  }

  .close-btn {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .popup {
    width: 220px;
    height: 160px;
    top: 80%;
    right: 5%;
    padding: 10px;
    font-size: 10px;
  }

  .popup p {
    font-size: 10px;
  }

  .close-btn {
    font-size: 14px;
  }
}
