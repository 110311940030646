.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
  margin: 0 auto;
  background-color: #f5f5f5;
  
}

.cricket-ball-loader {
  overflow: hidden;
  width: fit-content;
  margin: 10rem auto;
}

.spinner {
  width: auto;
  height: 7.5rem;
  animation: spin 1s infinite linear;
  transform-origin: center center;
}


