/* Default Styles */
.playapp_head h1 {
  font-family: "Quicksand";
  text-align: start;
  font-size: 45px;
  line-height: 60px;

  color: #003e90;
  font-weight: 700;
}
.app_subheading {
  display: flex;
  justify-content: start;
  align-items: start;
  z-index: 2;
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  font-family: "Quicksand";
  color: #1f1f1f;
}
/* For screens up to 1024px */
@media (max-width: 1024px) {
  .playapp_head h1 {
    font-size: 50px;
    line-height: 55px;
  }
}

/* For screens up to 768px */
@media (max-width: 768px) {
  .playapp_head h1 {
    font-size: 20px;
    line-height: 45px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

/* For screens up to 425px */
@media (max-width: 425px) {
  .playapp_head h1 {
    font-size: 20px !important;
    line-height: 38px;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

/* For screens up to 375px */
@media (max-width: 375px) {
  .playapp_head h1 {
    font-size: 20px;
    line-height: 34px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* For screens up to 320px */
@media (max-width: 320px) {
  .playapp_head h1 {
    font-size: 18px;
    line-height: 30px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
