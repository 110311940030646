/* src/styles/global.css */
:root {
  --font-family-heading: "Quicksand", sans-serif; /* For h1, h2, h3, etc. */
  

  --font-h1: 2.441em;
  --font-h2: 1.953em;
  --font-h3: 1.563em;
  --font-h4: 1.25em;
  --font-h5: 1.25em;
  --font-h6: 0.875em;
  --font-p: 1em;
  --font-span: 0.975em;
}

/* Adjust for mobile devices */
@media (max-width: 767px) {
  :root {
    --font-h1: 1.875em; /* Approximately 30px */
    --font-h2: 1.625em; /* Approximately 26px */
    --font-h3: 1.375em; /* Approximately 22px */
    --font-h4: 1.125em; /* Approximately 18px */
    --font-h5: 1em; /* Approximately 16px */
    --font-h6: 0.875em; /* Approximately 14px */
    --font-p: 1em; /* Approximately 16px */
    --font-span: 0.875em; /* Approximately 14px */
  }
}

/* Adjust for devices 721px and smaller */
@media (max-width: 721px) {
  :root {
    --font-h1: 1.35em; /* Approximately 28px */
    --font-h2: 1.25em; /* Approximately 24px */
    --font-h3: 1.15em; /* Approximately 20px */
    --font-h4: 1em; /* Approximately 16px */
    --font-h5: 0.875em; /* Approximately 14px */
    --font-h6: 0.75em; /* Approximately 12px */
    --font-p: 0.875em; /* Approximately 14px */
    --font-span: 0.75em; /* Approximately 12px */
  }
}

:root {
  /* Default values for larger screens */
  --global-margin: 2vw;
  --global-padding: 2vh;
}

/* Adjust for mobile devices (767px and smaller) */
@media (max-width: 767px) {
  :root {
    --global-margin: 4vw;
    --global-padding: 4vh;
  }
}

/* Adjust for devices 721px and smaller */
@media (max-width: 721px) {
  :root {
    --global-margin: 3.5vw;
    --global-padding: 3.5vh;
  }
}

/* Adjust for devices 600px and smaller */
@media (max-width: 600px) {
  :root {
    --global-margin: 3vw;
    --global-padding: 3vh;
  }
}

/* Adjust for devices 480px and smaller */
@media (max-width: 480px) {
  :root {
    --global-margin: 2.5vw;
    --global-padding: 2.5vh;
  }
}

/* Adjust for devices 360px and smaller */
@media (max-width: 360px) {
  :root {
    --global-margin: 2vw;
    --global-padding: 2vh;
  }
}

/* Example usage */
.some-class {
  margin: var(--global-margin);
  padding: var(--global-padding);
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Quicksand:wght@300..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

html,
body,
#root {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling on the entire page */
}

body {
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Nunito";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a{
  text-decoration: none;
}
.greenColor {
  color: green !important;
}


.model-title {
  text-align: center;
  margin: 50px auto; /* Adjusted for smaller screens */
  font-size: 2.5rem; /* Responsive font size */
  color: #272c60;
  max-width: 90%; /* Ensure title fits within the viewport */
  box-sizing: border-box; /* Include padding and borders in width */
}

.divider {
  width: 75px;
  height: 4px;
  background-color: #272c60;
  position: relative;
  margin: 20px auto 60px;
}

.members {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px; /* Add padding to prevent content from touching edges */
  max-width: 100%; /* Ensure container does not exceed viewport */
  box-sizing: border-box; /* Include padding and borders in width */
  overflow: hidden; /* Ensure no horizontal scroll for container */
}

.member {
  margin: 20px 15px;
  border-radius: 10px;
  padding: 0;
  height: 400px;
  overflow: hidden;
  flex: 1 1 calc(33% - 30px); /* Responsive sizing: 3 columns with space */
  max-width: calc(33% - 30px); /* Max width for responsiveness */
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .member {
    flex: 1 1 calc(50% - 30px); /* 2 columns */
    max-width: calc(50% - 30px);
  }

  .model-title {
    font-size: 2rem; /* Smaller font size */
  }
}

@media (max-width: 768px) {
  .member {
    flex: 1 1 calc(100% - 30px); /* 1 column */
    max-width: calc(100% - 30px);
    /* Ensure no horizontal scroll for individual items */
    overflow: hidden;
  }

  .model-title {
    font-size: 1.5rem; /* Smaller font size */
    margin: 20px auto; /* Adjusted margin */
  }
}

@media (max-width: 480px) {
  .model-title {
    font-size: 1.25rem; /* Even smaller font size */
  }

  .members {
    padding: 0; /* Remove extra padding on very small screens */
  }

  .member {
    margin: 10px 5px; /* Adjust margins for very small screens */
    height: auto; /* Allow height to adjust based on content */
  }
}
