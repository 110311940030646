.testimonial {
  padding: 1.5rem;
  background-color: #f9f9f9;
}

.testimonial-slider-container {
  max-width: 100%;
  margin: 0 auto; /* Center the slider */
}

.slick-arrow-icon {
  color: #ff6a00;
  font-size: 20px; /* Adjust font size for smaller screens */
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem; /* Add padding for better spacing */
}

.testimonial-avatar {
  width: 70px; /* Smaller size for mobile */
  height: 70px;
  margin-bottom: 0.8rem;
}

.testimonial-description {
  font-size: 15px; /* Adjusted for readability on smaller screens */
  font-family: "Nunito";
  margin-bottom: 0.8rem;
  color: #555;
  text-align: justify;
  line-height: 1.4; /* Improve text spacing */
}

.testimonial-name {
  font-weight: bold;
  font-family: "Nunito";
  font-size: 16px; /* Scale text size */
}

/* Slick dots styles */
.slick-dots li button:before {
  font-size: 10px; /* Smaller dots */
  color: #333;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

/* Responsive adjustments for 425px */
@media (max-width: 425px) {
  .testimonial {
    padding: 1rem;
  }
  .testimonial-card {
    padding:2rem;
  }
  .testimonial-avatar {
    width: 60px;
    height: 60px;
  }
  .testimonial-description {
    font-size: 15px;
  }
  .testimonial-name {
    font-size: 15px;
  }
  .slick-arrow-icon {
    font-size: 18px;
  }
}

/* Responsive adjustments for 375px */
@media (max-width: 375px) {
  .testimonial {
    padding: 0.8rem;
  }
  .testimonial-card {
    padding: 0.6rem;
  }
  .testimonial-avatar {
    width: 50px;
    height: 50px;
  }
  .testimonial-description {
    font-size: 12px;
  }
  .testimonial-name {
    font-size: 14px;
  }
  .slick-arrow-icon {
    font-size: 16px;
  }
}

/* Responsive adjustments for 320px */
@media (max-width: 320px) {
  .testimonial {
    padding: 0.5rem;
  }
  .testimonial-card {
    padding: 0.5rem;
  }
  .testimonial-avatar {
    width: 40px;
    height: 40px;
  }
  .testimonial-description {
    font-size: 11px;
  }
  .testimonial-name {
    font-size: 13px;
  }
  .slick-arrow-icon {
    font-size: 14px;
  }
}
